/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*, *:before, *:after {
  box-sizing: border-box;
}

.copy {
	.alignnone {
		margin: 2px 20px 20px 0;
	}
	.aligncenter,
	div.aligncenter {
		display: block;
		margin: 10px auto 13px auto;
	}
	.alignright {
		float:right;
		margin: 2px 0 25px 25px;
	}
	.alignleft {
		float: left;
		margin: 2px 25px 25px 0;
	}
	a img.alignright {
		float: right;
		margin: 2px 0 25px 25px;
	}
	a img.alignnone {
		margin: 2px 25px 25px 0;
	}
	a img.alignleft {
		float: left;
		margin: 2px 25px 25px 0;
	}
	a img.aligncenter {
		display: block;
		margin-left: auto;
		margin-right: auto
	}
	// .wp-caption {
	// background: #fff;
	// border: 1px solid #f0f0f0;
	// max-width: 96%; Image does not overflow the content area
	// padding: 5px 3px 10px;
	// text-align: center;
	// }
	.wp-caption.alignnone {
		margin: 5px 20px 20px 0;
	}
	.wp-caption.alignleft {
		margin: 5px 20px 20px 0;
	}
	.wp-caption.alignright {
		margin: 5px 0 20px 20px;
	}
	.wp-caption img {
		border: 0 none;
		height: auto;
		margin: 0;
		max-width: 98.5%;
		padding: 0;
		width: auto;
	}
	// .wp-caption p.wp-caption-text {
	// 	font-size: 11px;
	// 	line-height: 17px;
	// 	margin: 0;
	// 	padding: 0 4px 5px;
	// }
	.wp-editor-wrap a {
		height: auto;
		width: auto;
	}
	.wp-editor-wrap * {
		box-sizing: content-box;
	}
	img {
		max-width: 100%;
		height: auto;
	}
}