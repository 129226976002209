.vertical-center {
	display: table;
	width: 100%;
	height: 100%;
	
	.cell {
		display: table-cell;
		vertical-align: middle;
	}

	&.bottom {
		.cell {
			vertical-align: bottom;
		}
	}
}