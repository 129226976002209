$h_font: 'Montserrat', Helvetica, Arial, sans-serif;
$b_font: 'Montserrat', Helvetica, Arial, sans-serif;

html,
body {
	font-family: $b_font;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
}

h1, h2, h3, h4, h5, h6 {
	margin-bottom: 0.5em;
	font-family: $h_font;
	font-weight: 700;

	&.no-m {
		margin-bottom: 0;
	}
}

h1 {
	font-size: 4rem;

	@include max-down(600px) {
		font-size: 2rem;
	}
}

h2 {
	font-size: 1.8rem;

	@include max-down(600px) {
		font-size: 1.4rem;
	}
}

.copy {
	color: $black;

	a {
		display: inline-block;
		color: inherit;

		&:focus {
			outline: 1px solid blue;
		}

		@include non-touch {
			&:hover {
				color: lighten($black, 20%);
			}
		}
	}

	p {
		margin-bottom: 1em;
		font-size: 1rem;
		line-height: 1.4em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	strong {
		font-weight: bold;
	}

	em {
		font-style: italic;
	}

	ul {
		list-style: disc;
		margin: 1em 0;
		padding-left: 25px;

		li {
			font-size: 1rem;
			line-height: 1.2em;
			margin-bottom: 0.2em;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
	
	ol {
		list-style: decimal;
		margin: 1em 0;
		padding-left: 25px;

		li {
			font-size: 1rem;
			line-height: 1.2em;
			margin-bottom: 0.2em;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&.white {
		color: $white;
	}
}