.btn, input[type="submit"], button[type="submit"] {
	appearance: none;
	display: inline-block;
	vertical-align: middle;
	min-width: 200px;
	border: 2px solid $black;
	background: none;
	outline: 0;
	border-radius: 0;
	padding: 10px 25px;
	margin: 0;
	cursor: pointer;

	font-size: 1rem;
	line-height: 1em;
	color: $black;
	text-decoration: none;
	text-align: center;

	&:nth-child(2) {
		margin-left: 15px;
	}

	&.hover,
	&:hover {
		background: $black;
		color: $white;
	}

	&.disabled,
	&:disabled {
		opacity: 0.3;
		pointer-events: none;
		cursor: not-allowed;
	}
}