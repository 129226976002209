.home {
	width: 100%;
	height: 100vh;

	.inner-content-wrap {
		width: 80%;
		margin: 0 auto;

		ul {
			margin-top: 55px;
			padding-left: 0;
			font-size: 0;

			li {
				display: inline-block;
				margin-right: 25px;
				font-size: 1rem;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	@include max-down(600px) {
		height: auto;
		padding: 10vw 0;

		.inner-content-wrap ul li {
			display: block;
			margin-right: 0;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}